import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Router } from "@angular/router";

import { ToastrService } from 'ngx-toastr';

import { CONSTANTS } from "./../../core/constants/constants";
import { ToastrOpcoes } from '../../models/toastr-opcoes.interface';
import Swal from 'sweetalert2';
import { Instance } from 'src/app/features/cadastros/instancia/instancia.component';
import { StorageService } from 'src/app/core/storage/storage.service';
import { FormGroup } from '@angular/forms';


const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  width: '95.8%',
  confirmButtonText: 'X',
  customClass: {
    confirmButton: 'btn btn-sm ',
    title: 'title-alt',

    container: 'swal-custom-config'
  },
  animation: false
})

export const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-sm btn-success ',
    cancelButton: 'btn btn-sm btn-danger ml-2',
  },
  width: 350,
  buttonsStyling: false,
})


@Injectable({
  providedIn: "root"
})

export class UtilsService {

  CONSTANTS = CONSTANTS;

  constructor(
    private alertMsg: ToastrService,
    private http: HttpClient,
    private router: Router,
    private storage: StorageService
  ) {

  }


  //remove o número 0 dos arrays, esse número representa a opçao "todos", dos drops
  removeAllOptions(array: any[]) {
    let arrayCopy = [...array]
    let index = arrayCopy.findIndex(object => {
      return object == 0
    })
    if (index != -1) {  
      arrayCopy.splice(index, 1)
    }
    return arrayCopy
  }

  removeAllOptionsV2(array: any[],queryField) {
    let arrayCopy = [...array]
    let queryFilter = ""
    let index = arrayCopy.findIndex(object => {
      return object == 0
    })
    if (index != -1) {      
      queryFilter = ""
    } else {
      queryFilter = `&${queryField}=${arrayCopy.toString()}`
    }
    return queryFilter
  }
  
  getInstanceLoginId() {
    let instanceLogin: Instance = this.storage.getInstanceLogin()
    return instanceLogin.Id
  }

  closeAlertRequest() {
    Toast.close();
  }

  showAlertInsanceDisabled() {
    let language
    switch (localStorage.getItem('appLanguage')
    ) {
      case 'en':
        language = require("../../../assets/i18n/en.json")
        break;
      case 'pt':
        language = require("../../../assets/i18n/pt.json")
        break;
      default:
        console.log(`Sorry, we are out of.`);
    }
    Toast.fire(``, `${language.REGISTER.USER.INSTANCEDISABLED}`, 'error');
  }

  showAlertTimeout() {
    let language
    switch (localStorage.getItem('appLanguage')
    ) {
      case 'en':
        language = require("../../../assets/i18n/en.json")
        break;
      case 'pt':
        language = require("../../../assets/i18n/pt.json")
        break;
      default:
        console.log(`Sorry, we are out of.`);
    }
    Toast.fire(``, `${language.DEFAULT.TIMEOUT}`, 'error');
  }

  showAlertRequestError(err: HttpErrorResponse) {
    let language = localStorage.getItem('appLanguage')    
    switch (err.status) {
      case 500:
      case 404:
        Toast.fire(`Ocorreu um erro.`, ` Message: ${err.message}. Status: ${err.status}`, 'error');
        break;
      case 400:
        let msgError
        if (language == 'pt') {
          msgError = err.error.error
        } else {
          if (err.error.error == 'Code já cadastrado, Verifique!') {
            msgError = 'Code already exists, verify.'
          } else if (err.error.error == 'Name já cadastrado, Verifique!') {
            msgError = 'Name already exists, verify.'
          } else if (err.error.error == 'Não é possível excluir o registro, referenciado em outra tabela, verifique!') {
            msgError = "Can't delete register, already referenced in another table, verify."
          } else if (err.error.error == 'Nenhum registro encontrado para o filtro informado, verifique!' || err.error.error == 'Nenhum registro encontrado, verifique!') {
            msgError = "No register found with the informed filter, verify."
          }
          else {


            msgError = err.error.error
          }
        }
        //language == 'pt' ? msgError = err.error.error : msgError = "No records found for the given query, verify!"        
        // Toast.fire('', ` Message: ${err.error.error}. Status: ${err.status}`, 'info');
        Toast.fire('', `${msgError}`, 'info');
        break;
      case 403:
        Toast.fire(`Ocorreu um erro.`, ` Message: ${err.error.error} Status: ${err.status}`, 'error');
        break;
      case 408:
        Toast.fire(`Tempo de resposta excedido`, ` Message: ${err.error.error}. Status: ${err.status}`, 'warning');
        break;
      default:
        Toast.fire(`${err.message},`, `Stats: ${err.status}`, 'warning')
    }
  }

  showAlertRequestSuccess(data: HttpResponse<any>) {
    
    switch (data.status) {
      case 200:
        if (localStorage.getItem('appLanguage') == 'pt') {
          Toast.fire('', `${data.body.result}`, 'success');
        } else {
          if (data.body.result[0] == 'A') {
            Toast.fire('', `Change made successfully`, 'success');
          } else if (data.body.result[0] == 'C') {
            Toast.fire('', `Created successfully`, 'success');
          } else {
            Toast.fire('', `Removed successfully`, 'success');
          }
        }
        break;
      default:
        Toast.fire(`${data.body},`, `Status: ${data.status}`, 'warning')
    }
  }

  showAlertRequestInstanceChange(message) {
    Toast.fire('', `${message}`, 'success');
  }

  showAlertRequestSuccessDiscardReprocess(data: HttpResponse<any>) {
    //        Toast.fire('', `${data.body.result}`, 'success');

    // Toast.fire(`${data['result']}`, '', 'success')
    Toast.fire('', `${data['result']}`, 'success')
    // switch (data.status) {
    //   case 200: Toast.fire(`${data.body.result}`, '', 'success')
    //     break;
    //   default:
    //     Toast.fire(`${data.body},`, `Status: ${data.status}`, 'warning')
    // }
  }


  showAlert(typeMsg: string, msg: string, title: string = '', typeError?: any, opcoes: ToastrOpcoes = null) {

    let opt = opcoes as {}
    switch (typeMsg) {
      case 'EC':
        // Toast.fire(`${title},`,msg,'success')        
        Toast.fire(`${title}`, msg, 'error')
        break;
      case 'FS':
        // Toast.fire(`${title},`,msg,'success')
        Toast.fire(`${title}`, msg, 'success')
        break;
      case 'S':
        if (title == '') {
          title = 'Sucesso'
        }
        Toast.fire(``, msg, 'success')
        // this.alertMsg.success(msg, title, opt);
        break;
      case 'W':
        if (title == '') {
          title = 'Alerta'
        }
        // this.alertMsg.warning(msg, title, opt);
        Toast.fire(``, msg, 'warning')

        break;
      case 'E':
        if (title == '') {
          title = 'Erro'
        }
        switch (typeError.status) {
          case 400:

            this.alertMsg.error(typeError.error.error.message.value, title, opt);
            break;
          case 401:
            this.alertMsg.error('Você não tem autorizacão para esta operação', title, opt);
            break;
          case 404:
            this.alertMsg.error('404 não encontrado', title, opt);
            break;
          case 405:
            this.alertMsg.error('Método de requisição não autorizado', title, opt);
            break;
          case 408:
            this.alertMsg.error('Tempo de resposta excedido', title, opt);
            break;
          default:
            this.alertMsg.error('Algum erro aconteceu', title, opt);
        }
        break;
      case 'errorCielo':
        this.alertMsg.error('Comunicação com a Cielo falhou.', title, opt)
        break;
      default:
        this.alertMsg.info(msg, title, opt);
    }

  }

  //valida erros nos campos de formulário
  public hasError(form: FormGroup, controlName: string, errorName: string) {

    return form.controls[controlName].hasError(errorName);
  }

  //necessário para atualizar o valor do mat-select
  compareValues(value1: any, value2: any): boolean {

    return value1 == value2;
  }
  ////// MSG //////
  /////////////////

  /////////////////
  ////// USER /////
  /** @description retorna dados de usuario
  */
  getUserLogged() {
    return this.http.get(this.CONSTANTS.MOOKENDPOINT.GETLOGEDUSER);

  }

  ////// USER /////
  /////////////////


  /////////////////
  ////// Data service /////

  formatDateToString(date: Date, padraoBrasileiro = false) {
    let str = new Date(date)
    if (date) {
      if (!padraoBrasileiro) {
        return str.getFullYear() + '-' + (str.getMonth() + 1) + '-' + str.getDate();
      } else {
        let month = str.getMonth() + 1
        return (str.getDate().toString().length == 2 ? str.getDate() : '0' + str.getDate()) + "/" + (month.toString().length == 2 ? month : '0' + month) + "/" + str.getFullYear();
      }
    }
  }

  /** @description converta data formato 10/10/2010 em DATE
  * @param {strDate} strDate dias ha somar
  */
  formatStringToDate(strDate: any) {
    let ret = strDate.split("/");
    let month = ret[1] - 1
    return new Date(ret[2], month, ret[0])
  }

  formataDataAmericano(date) {
    let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  /** @description soma dias a uma data
  * @param {soma} soma dias ha somar
  * @param {date} date data formato ISOString
  */
  somarDia(soma: number, date: string) {
    let a = date.substring(0, 8)
    let b = parseInt(date.substring(8, 10)) + soma
    let c = date.substring(10, 24)
    return a + b + c;
  }

  /** @description subitrai dias de uma data
  * @param {sub} sub dias ha subtrair
  * @param {date} date data formato ISOString
  */
  subtrairDia(sub: number, date: string) {
    let a = date.substring(0, 8);
    let b = parseInt(date.substring(8, 10)) - sub;
    let c = date.substring(10, 24);
    return a + b + c;
  }

  ////// Data service  /////
  /////////////////


  getSavedSearch() {
    return this.http.get(this.CONSTANTS.MOOKENDPOINT.GETSAVEDSEARCH);
  }

  deleteSavedSearch(id) {
    return this.http.get(this.CONSTANTS.MOOKENDPOINT.GETSAVEDSEARCH);
  }

  getEnableIntegrations() {
    return this.http.get(this.CONSTANTS.MOOKENDPOINT.GETENABLEINTEGRATIONS);
  }
  getEnableSites() {
    return this.http.get(this.CONSTANTS.MOOKENDPOINT.GETENABLESITES);
  }
  setLanguage() {
    let language
    //src/app/features/cadastros/instancia/instancia.component
    switch (localStorage.getItem('appLanguage')) {
      case 'en':
        language = require("../../../assets/i18n/en.json")
        break;
      case 'pt':
        language = require("../../../assets/i18n/pt.json")
        break;
      default:
        console.log(`Sorry, we are out of.`);
    }
    return language
  }

}